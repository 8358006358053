<div class="exception-body error">
    <div class="exception-panel">
        <div class="exception-panel-content">
            <img src="assets/layout/images/exception/error-icon.png">
            <h1>Error Occured</h1>
            <p>An error occured, please try again later.</p>
        </div>

        <div class="exception-panel-footer">
            <button type="button" pButton [routerLink]="['/']" label="DASHBOARD" class="p-button-info"></button>
        </div>
    </div>
</div>
