export class Documento {
    id?: number;
    nomeArquivo?: string;
    tipoDeclaracao?: string;
    anoReferencia?: number;
    anoCalendario?: number;
    identificadorLeiaute?: string;
    retificadora?: string;
    numeroRecibo?: string;
    pathConsolidado?: string;
    status?: string;
    dataCriacao?: Date;
    usuarioCriacao?: string;
    id_documento?: string;
    observacao?: string;


    constructor(
        anoReferencia?: number,
        anoCalendario?: number,
        identificadorLeiaute?: string,
        retificadora?: string,
        numeroRecibo?: string,
        id_documento?: string,
    ) {
        this.tipoDeclaracao = 'DIRF';
        this.anoReferencia = anoReferencia;
        this.anoCalendario = anoCalendario;
        this.identificadorLeiaute = identificadorLeiaute;
        this.retificadora = retificadora;
        this.numeroRecibo = numeroRecibo;
        this.id_documento = id_documento;
    }


}

export interface FiltroDocumento {
    nomeArquivo?: string;
    retificadora?: ListaPadrao;
    anoReferencia?: Date;
    status?: ListaPadrao;
    tamanhoPagina?: number;
    numPagina?: number;

}

export interface DocumentoResumoDTO {
    id?: number,
    nomeArquivo?: string;
    retificadora?: string;
    dataCriacao?: Date;
    usuarioCriacao?: string;
    status?: string,
}

export interface ListaPadrao {
    code?: string;
    nome?: string;
}

export class DocumentoPaginadoDTO {
    qtdRegistros: number;
    qtdPaginas: number;
    documentos: Documento[];
}