import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from "src/environments/environment";
import { Documento, DocumentoPaginadoDTO, FiltroDocumento } from '../domain/dirf';



@Injectable()
export class DirfService {

    constructor(private http: HttpClient) { }

    async uploadArquivo(files: File[], documento: Documento) {

        try {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }
            formData.append('tipoDeclaracao', 'DIRF');
            formData.append('anoReferencia', documento.anoReferencia.toString());
            formData.append('anoCalendario', documento.anoCalendario.toString());
            formData.append('identificadorLeiaute', documento.identificadorLeiaute);
            formData.append('retificadora', documento.retificadora);
            formData.append('numeroRecibo', documento.numeroRecibo);

            return await this.http.post<any>(`${env.apiUrlBase}/v1/arquivo`, formData).toPromise();
        } catch (e) {
            console.error('Erro ao fazer upload do arquivo:', e);
            if (e instanceof HttpErrorResponse) {
                console.error('Status do erro HTTP:', e.status);
                console.error('Resposta do erro HTTP:', e.error);
            }
            throw new Error('Erro ao fazer upload do arquivo');

        }

    }

    getDocumentoPaginado(filtroDocumento: FiltroDocumento) {

        let url = `${env.apiUrlBase}/v1/arquivo/paginado`;
        let controleUrl = url.length;

        if (filtroDocumento.tamanhoPagina !== null && filtroDocumento.tamanhoPagina !== undefined) {
            url = url.concat(`${url.length === controleUrl ? '?' : '&'}tamanhoPagina=${filtroDocumento.tamanhoPagina}`);
        }
        if (filtroDocumento.numPagina !== null && filtroDocumento.numPagina !== undefined) {
            url = url.concat(`${url.length === controleUrl ? '?' : '&'}numPagina=${filtroDocumento.numPagina}`);
        }
        if (filtroDocumento.nomeArquivo !== null && filtroDocumento.nomeArquivo !== undefined && filtroDocumento.nomeArquivo.toString().trim() !== '') {
            url = url.concat(`${url.length === controleUrl ? '?' : '&'}nomeArquivo=${filtroDocumento.nomeArquivo.toString().trim()}`);
        }
        if (filtroDocumento.retificadora !== null && filtroDocumento.retificadora !== undefined) {
            url = url.concat(`${url.length === controleUrl ? '?' : '&'}retificadora=${filtroDocumento.retificadora.code}`);
        }
        if (filtroDocumento.anoReferencia !== null && filtroDocumento.anoReferencia !== undefined && filtroDocumento.anoReferencia.toString().trim() !== '') {
            url = url.concat(`${url.length === controleUrl ? '?' : '&'}anoReferencia=${filtroDocumento.anoReferencia.getFullYear()}`);
        }
        if (filtroDocumento.status !== null && filtroDocumento.status !== undefined) {
            url = url.concat(`${url.length === controleUrl ? '?' : '&'}status=${filtroDocumento.status.code}`);
        }

        return this.http.get<DocumentoPaginadoDTO>(`${url}`)
            .toPromise()
            .then(res => res as DocumentoPaginadoDTO)
            .then(data => data);

    }

    downloadDirf(pathCompleto: string) {

        const options = {
            responseType: 'arraybuffer' as 'json'
        };
        return this.http.post<ArrayBuffer>(`${env.apiUrlBase}/v1/arquivo/download-dirf`, pathCompleto, options)
            .toPromise()
            .then(data => data);
    }

    downLoadFile(data: ArrayBuffer, id: number, nomeArquivo: string) {

        const fileName = `${id}_arquivo_${nomeArquivo}.txt`;
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';

        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = fileName;
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }


}