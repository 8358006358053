import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AppMainComponent } from './app.main.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { CanActivateGuard } from './security/guard/canactivateguard';


//timp
import { DirfComponent } from './timp/view/dirf.component';
import { InformeComponent } from './timp/view/informe.component';


const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    { path: '', component: DirfComponent, canActivate: [CanActivateGuard, MsalGuard] },
                    { path: 'timp/cosolidador', component: DirfComponent, canActivate: [CanActivateGuard, MsalGuard] },
                    { path: 'timp/informe', component: InformeComponent, canActivate: [CanActivateGuard, MsalGuard] },
                    { path: 'code', component: EmptyDemoComponent, canActivate: [CanActivateGuard, MsalGuard] },
                ],
            },
            { path: 'error', component: AppErrorComponent },
            { path: 'access', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'login', component: AppLoginComponent },
            { path: '**', redirectTo: '/notfound' }
        ], { scrollPositionRestoration: 'enabled', initialNavigation: !isIframe ? 'enabled' : 'disabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
