import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable, of } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { AuthService } from '../auth.service';


@Injectable({ providedIn: 'root' })
export class CanActivateGuard implements CanActivate {
    constructor(private msalService: MsalService,
        private authService: AuthService,
        private router: Router,
        private msalBroadcastService: MsalBroadcastService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                switchMap(() => {
                    if (this.msalService.instance.getAllAccounts().length > 0) {
                        var roles = this.msalService.instance.getAllAccounts()[0].idTokenClaims.roles;
                        if (roles !== undefined) {
                            return of(true);
                        } else {
                            this.router.navigate(['/access']);
                            return of(false);
                        }
                    }

                    this.authService.redirectUrl = state.url;
                    this.router.navigate(['/login']);
                    return of(false);
                })
            );
    }
}