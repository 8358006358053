<div class="exception-body notfound">
    <div class="exception-panel">
        <div class="exception-panel-content">
            <img src="assets/layout/images/exception/404-icon.png">
            <h1>404</h1>
            <p>The requested page cannot be found.</p>
        </div>

        <div class="exception-panel-footer">
            <button type="button" pButton [routerLink]="['/']" label="DASHBOARD" class="p-button-info"></button>
        </div>
    </div>
</div>
