<div class="background-body">

    <div class="exception-body">
        <div class="exception-panel">
            <div class="exception-panel-content">
                <img src="assets/layout/images/exception/access-icon.png">
                <h1>Sem Acesso</h1>
                <p>Você não tem os acessos necessários na aplicação. Abra um <b>chamado</b> de solicitação de acesso no
                    <b>Portal
                        Interno da Saúde Petrobras</b> para regularizar seus acessos.</p>
            </div>

            <div class="exception-panel-footer">
                <button type="button" pButton [routerLink]="['/login']" label="LOGIN" class="p-button-info"></button>
            </div>
        </div>
    </div>
</div>