import { Component, OnInit, Renderer2 } from '@angular/core';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';


declare var require: any


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    menu = 'static';

    layout = 'deepsea';

    darkMenu = false;

    inputStyle = 'filled';

    ripple: boolean;

    language: string = 'pt-br'

    supportLanguages = ['en']

     VLRibras: any;

    constructor(private primengConfig: PrimeNGConfig, private translateService: TranslateService, private renderer: Renderer2) { 

        this.translateService.addLangs(this.supportLanguages);
        this.translateService.setDefaultLang('pt-br');

        this.translateService.use('pt-br');

        const language: Translation = require(`../assets/i18n/pt-br.json`);
        this.primengConfig.setTranslation(language);

    }

    ngOnInit() {
          this.primengConfig.ripple = true;
        this.loadVLibrasScript();
    }

    translate() {
        this.translateService.use(this.language);
        const language: Translation =  require(`../assets/i18n/${this.language}.json`);
        this.primengConfig.setTranslation(language);
    }
    loadVLibrasScript(): void {
        const script = this.renderer.createElement('script');
        script.src = 'https://vlibras.gov.br/app/vlibras-plugin.js';
        script.onload = () => {
            // Após o script ser carregado, inicialize o VLibras
            new (window as any).VLibras.Widget('https://vlibras.gov.br/app');
        };
        this.renderer.appendChild(document.body, script);
    }
  
}
