import { Component } from '@angular/core';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './app.accessdenied.component.html',
  styleUrls: ['./app.accessdenied.component.css']
})
export class AppAccessdeniedComponent {

}
