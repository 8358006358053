import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnChanges {
  @Input() buttonType: string;

  labelKey: string;
  icon: string;
  ngClass: string;
  disabled: string

  ngOnChanges(changes: SimpleChanges) {
    if (changes.buttonType) {
      this.updateButtonConfig();
    }
  }

  private createButtonConfig(labelKey: string, icon: string, ngClass: string,disabled: string) {
    return {
      labelKey: labelKey,
      icon: icon,
      ngClass: ngClass,
     disabled: disabled,
      click: () => {}
    };
  }
  private updateButtonConfig() {
    let config;

    if (this.buttonType === 'new') {
      config = this.createButtonConfig('default.btnNew', 'pi pi-plus', 'p-button-success','');
    } else if (this.buttonType === 'exclude') {
      config = this.createButtonConfig('default.btnDelete', 'pi pi-trash', 'p-button-text p-button-danger','');
    } else if (this.buttonType === 'search') {
      config = this.createButtonConfig('default.btnSearch', 'pi pi-search', 'p-button-info search','');
    } else if (this.buttonType === 'clear') {
      config = this.createButtonConfig('default.btnClear', 'pi pi-filter-slash', 'p-button-warning','');
    } else if (this.buttonType === 'close') {
      config = this.createButtonConfig('default.btnClose', 'pi pi-times', 'p-button-text','');
    } else if (this.buttonType === 'upload') {
      config = this.createButtonConfig('default.btnUpload', 'pi pi-upload', 'p-button-success','');
    } else if (this.buttonType === 'download') {
      config = this.createButtonConfig('default.btnDownload', 'pi pi-download', 'p-button-text p-button-success','');
    } else if (this.buttonType === 'save') {
      config = this.createButtonConfig('default.save', 'pi pi-check', 'p-button-text p-button-success','');
    } 
    else {
      config = this.createButtonConfig('default.btnNotFound', '', 'p-button-danger','');
    }
    






    if (config) {
      this.labelKey = config.labelKey;
      this.icon = config.icon;
      this.ngClass = config.ngClass;
    }
  }

 

}
