

import { Component, OnInit } from "@angular/core";
import { ImageModule } from 'primeng/image';
import { MsalService } from "@azure/msal-angular";
import { MessageService, ConfirmationService } from "primeng/api";
import { FiltroInformeRendimentos, RendimentosCnpjDTO, RendimentosCpfDTO, ListaRendimentosDTO} from "../domain/informe";
import { InformeService } from "../service/informe.service";

@Component({
    templateUrl: './informe.component.html',
    providers: [MessageService, ConfirmationService],
    styleUrls: ['../../../assets/demo/badges.scss','./informe.component.css'],
    styles: [`
    :host ::ng-deep .p-frozen-column {
      font-weight: bold;
    }

    :host ::ng-deep .p-datatable-frozen-tbody {
      font-weight: bold;
    }

    :host ::ng-deep .p-progressbar {
      height: .5rem;
    }
  `]
})

export class InformeComponent implements OnInit {

    rendimentos: any[] = [];
    submitted: boolean = false;
    rendimentoCpfList: RendimentosCpfDTO[] = [];
    rendimentoCpf: RendimentosCpfDTO;
    rendimentoCnpj: RendimentosCnpjDTO[] = [];
    rendimentoCnpjCabecalho: RendimentosCnpjDTO;
    filtroInformeRendimentos: FiltroInformeRendimentos;
    windowDownloadPdf: boolean = false;
    idDocumento: string;
   
    year = null;
  calendarYear = null;
    constructor(private msalService: MsalService, private informeService: InformeService, private messageService: MessageService) { }
    ngOnInit() {

   


        this.filtroInformeRendimentos = <FiltroInformeRendimentos>({
            cpfCnpj: null,
            anoCalendario: null,
            id_documento: null

        });


        
    }

    validaCampos(): boolean {
        if (!this.filtroInformeRendimentos.anoCalendario || !this.filtroInformeRendimentos.cpfCnpj) {
            return false;
        }

        return true;
    }

    buscar() {
        if (!this.validaCampos()) {
            this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'Todos os campos precisam ser preenchidos.', life: 3000 });
            return;
        }

        this.informeService.getInformeRendimentos(this.filtroInformeRendimentos)
            .then(data => {
                if (!data || (Array.isArray(data) && data.length === 0)) {
                    this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'A Busca Não Retornou Dados, Verifique os Campos e Tente Novamente.', life: 3000 });
                    return;
                }
                this.submitted = true;
                if (Array.isArray(data)) {
                    this.rendimentoCnpj = data as RendimentosCnpjDTO[];
                    this.rendimentoCpf = null;
                    this.rendimentoCpfList = [];
                    this.rendimentos = data;
                   
                    this.rendimentoCnpj.map(data=>{
                       this.filtroInformeRendimentos.idDocumento= data.idDocumento
                                        })
                    
                    this.rendimentoCnpjCabecalho = this.rendimentoCnpj[0];
                } else {
                    this.rendimentoCnpj = null;
                    this.rendimentoCnpjCabecalho = null;
                    this.rendimentoCpf = data as RendimentosCpfDTO;
                    this.year = this.rendimentoCpf.ano_calendario;
                    this.calendarYear = this.rendimentoCpf.ano_referencia;

                    this.filtroInformeRendimentos.idDocumento= this.rendimentoCpf.idDocumento;
                    this.rendimentoCpfList = [data as RendimentosCpfDTO];
                }

                this.submitted = false;
            })
            .catch(error => {
                this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'A Busca Não Retornou Dados, Verifique os Campos e Tente Novamente.', life: 3000 });
                this.submitted = false;
            });
    }


    limpaFiltro() {


        this.filtroInformeRendimentos = <FiltroInformeRendimentos>({
            cpfCnpj: null,
            anoCalendario: null,
            id_documento:null

        });
        this.rendimentoCnpj = null;
        this.rendimentoCpfList= [];
        this.rendimentoCpf = null;
        this.rendimentoCnpjCabecalho = null;
    }

    validateNumberInput(event: KeyboardEvent) {
        const pattern = /[0-9]/;

        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    }

    getNomeMes(numeroMes: string): string {
        switch (numeroMes) {
            case '01':
                return 'Janeiro';
            case '02':
                return 'Fevereiro';
            case '03':
                return 'Março';
            case '04':
                return 'Abril';
            case '05':
                return 'Maio';
            case '06':
                return 'Junho';
            case '07':
                return 'Julho';
            case '08':
                return 'Agosto';
            case '09':
                return 'Setembro';
            case '10':
                return 'Outubro';
            case '11':
                return 'Novembro';
            case '12':
                return 'Dezembro';
            case '13':
                return 'Décimo Terceiro';
            default:
                return '';
        }
    }
    formatarDocumento(documento: string): string {
        if (!documento) return documento;
        if (documento.length === 11) {
            return documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (documento.length === 14) {
            return documento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
        } else {
            return documento;
        }
    }
    DownloadPdf(){
        this.submitted = true;
        this.informeService.downloadReport(this.filtroInformeRendimentos).subscribe((response: Blob) => {
           if(response.size <=865){
            this.messageService.add({ severity: 'error', summary: 'Aviso', detail: 'Erro na geração do Informe por favor tente novamente.', life: 3000 });
            this.submitted = false;
           }else{
            const url = window.URL.createObjectURL(response);
            const a = document.createElement('a');
            a.href = url;
              
            this.submitted = false;
           
          window.open(a.href,"_blank")
           }
      });

    }

}