<div class="topbar">
    <div class="topbar-wrapper">
        <div class="topbar-left col-6">
            <a routerLink="/" class="topbar-logo">
                <img [src]="'assets/layout/images/logo.svg'" style="border: 0 none">
            </a>

            <a href="#" id="menu-button" (click)="appMain.onMenuButtonClick($event)">
                <i class="pi pi-bars"></i>
            </a>
        </div>
        <div class="flex justify-content-end">
            <button  class="buttonAcessibilidade" (click)="setFontSize('down')">A-</button>
            <button  class="buttonAcessibilidade" (click)="setFontSize('set')">A</button>
            <button  class="buttonAcessibilidade" (click)="setFontSize('up')">A+</button>
            <button  class="buttonAcessibilidade" (click)="toggleTheme()"><img class="icon" src="assets/layout/images/{{icon}}.svg">
            </button>
        </div>

        <div class="topbar-right">
            <a href="#" id="user-display" (click)="appMain.onTopbarMenuButtonClick($event)">
                <span class="username">{{profileUser?.displayName}}</span>
                <img [src]="imageToShow" style="border-radius: 50%;">
            </a>
            <ul id="topbar-menu" class="fadeInDown" [ngClass]="{'topbar-menu-visible': appMain.topbarMenuActive}">
                <li class="layout-topbar-action-item" (click)="logout()">
                    <a class="p-d-flex p-flex-row p-ai-center" pRipple>
                        <i class="pi pi-power-off" style='color: red; font-weight: 300;'></i>
                        <span>Sair</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>