<div class="grid">
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <div class="card">
                <h5>BUSCAR</h5>
                <div class="grid p-fluid">
                    <div class="col-12 md:col-3">
                        <div class="p-inputgroup">
                            <input type="text" [(ngModel)]="filtroDocumento.nomeArquivo" pInputText
                                placeholder="Arquivo">
                        </div>
                    </div>

                    <div class="col-12 md:col-3">
                        <p-dropdown [options]="retificadoraLista" [(ngModel)]="filtroDocumento.retificadora"
                            placeholder="Retificadora" optionLabel="nome" [showClear]="true"></p-dropdown>
                    </div>

                    <div class="col-12 md:col-3">
                        <p-calendar placeholder="Ano Referência (Ano Base)" [(ngModel)]="filtroDocumento.anoReferencia" view="year"
                            dateFormat="yy" inputId="yearpicker"></p-calendar>
                    </div>

                    <div class="col-12 md:col-3">
                        <p-dropdown [options]="statusProcessamentoLista" [(ngModel)]="filtroDocumento.status"
                            placeholder="Status" optionLabel="nome" [showClear]="true"></p-dropdown>
                    </div>
                </div>
                <div class="my-2">
                <app-button buttonType="search" (click)="buscar(true)"></app-button>
                <app-button buttonType="clear" (click)="limpaFiltro()"></app-button>
                </div>

            </div>
            <p-toolbar styleClass="mb-4">


                <button style="margin-left: auto;" pButton (click)="abrirCosolidaDirfForm()" pRipple
                    label="Anexar documentos" icon="pi pi-paperclip" class="p-button-success  mr-2"></button>
            </p-toolbar>
            <p-table #dt [value]="documentosGrid" [lazy]="true" (onLazyLoad)="buscarDocumentoPorPagina($event)"
                [columns]="cols" responsiveLayout="scroll"
                [globalFilterFields]="['data','codigoAutorizacao','carteirinhaUsuario','data','ean']" [rows]="10"
                [loading]="submitted" [totalRecords]="totalRegistrosGrid" [paginator]="true"
                [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
                currentPageReportTemplate="{{ 'default.showing' | translate }} {first} {{ 'default.to' | translate }} {last} {{ 'default.of' | translate }} {totalRecords} {{ 'default.entries' | translate }}"
                [(selection)]="selectedDocumentos" selectionMode="multiple" [rowHover]="" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">ARQUIVOS DIRF</h5>

                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Arquivo</th>
                        <th>Ano Referência (Ano Base)</th>
                        <th>Retificadora?</th>
                        <th>Download</th>
                        <th>Data criação</th>
                        <th>Usuário criador</th>
                        <th class="text-center">Status</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-documento>
                    <tr>
                        <td style="width:auto;"><span class="p-column-title">Arquivo</span>
                            {{documento.nomeArquivo}}
                        </td>
                        <td style="width:auto;"><span class="p-column-title">Ano_referencia </span>
                            {{documento.anoReferencia}}
                        </td>
                        <td style="width:auto;"><span class="p-column-title">Retificadora?</span>
                            {{documento.retificadora === 'S' ? 'SIM' : 'NÃO'}}
                        </td>
                        <td style="width:auto;"><span class="p-column-title">Download</span>
                            <p-button icon="pi pi-download" iconPos="left"
                                [disabled]="!documento.pathConsolidado" (click)="downloadDirf(documento.pathConsolidado, documento.id, documento.nomeArquivo)"></p-button>
                        </td>
                        <td style="width:auto;"><span class="p-column-title">Data </span>
                            {{documento.dataCriacao}}
                        </td>
                        <td style="width:auto;"><span class="p-column-title">Usuário criador</span>
                            {{documento.usuarioCriacao}}
                        </td>
                        <td style="width:auto; text-align: center;" data-toggle="tooltip" title="{{documento.observacao}}"><span class="p-column-title">Status</span>
                            {{documento.status}}
                        </td>


                    </tr>
                </ng-template>
            </p-table>
        </div>

        <!-- Consolidação de documentos -->

        <form #formulario="ngForm" (ngSubmit)="submitForm(formulario)">
            <p-dialog id="dirfModal" [(visible)]="consolidaDirfDialog" [closable]="true" [style]="{width: '60%'}"
                header="CONSOLIDAR DOCUMENTOS" [modal]="true" class="p-fluid" (onHide)="fecharDirfDialog(formulario)">
                <div class="grid p-fluid">
                    <div class="col-12 md:col-4">
                        <label class="block text-900 font-medium mb-2">Tipo de Declaração</label>
                        <input pInputText placeholder="Arquivo" value="DIRF" [disabled]="true">
                    </div>
                    <div class="col-12 md:col-4">
                        <label class="block text-900 font-medium mb-2">Ano Referência (Ano Base)</label>
                        <p-calendar (ngModelChange)="atualizarIdentificadorLeiaute(formulario)" placeholder="Ano Referência (Ano Base)" view="year" dateFormat="yy" inputId="yearpicker"
                            name="anoReferencia" ngModel required></p-calendar>
                    </div>
                    <div class="col-12 md:col-4">
                        <label class="block text-900 font-medium mb-2">Ano Calendário</label>
                        <p-calendar placeholder="Ano calendário" view="year" dateFormat="yy" inputId="yearpicker"
                            name="anoCalendario" ngModel required></p-calendar>
                    </div>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12 md:col-4">
                        <label class="block text-900 font-medium mb-2">Identificador de Leiaute</label>
                        <input  name="leiaute" ngModel required pInputText placeholder="Identificador de Leiaute" ngModel
                            maxlength="7">
                    </div>
                    <div class="col-12 md:col-4">
                        <label class="block text-900 font-medium mb-2">Retificadora</label>
                        <p-dropdown name="retificadora" [options]="retificadoraLista" [(ngModel)]="retificadora"
                            placeholder="Retificadora" optionLabel="nome" optionValue="code" [showClear]="true" (ngModelChange)="atualizarNumeroRecibo(formulario)">
                        </p-dropdown>
                    </div>
                    <div class="col-12 md:col-4">
                        <label class="block text-900 font-medium mb-2">Número do Recibo</label>
                        <input name="numeroRecibo" ngModel pInputText placeholder="Número do Recibo" ngModel
                            [disabled]="retificadora != 'S'" type="text"  (input)="limitarTamanho($event)" maxlength="12">
                    </div>
                    <div class="col-12 md:col-4">
                        <label class="block text-900 font-medium mb-2">Arquivos Legados</label>
                        <p-dropdown name="arquivosLegados" [options]="arquivosLegadosOpcoes" [(ngModel)]="arquivosLegados"
                            placeholder="Selecione" optionLabel="label" optionValue="value" [showClear]="true">
                        </p-dropdown>
                    </div>

                </div>
                <p-fileUpload name="myfile[]" class="fileUpload" #fileUpload maxFileSize="10000000"
                    chooseLabel="Adicionar arquivos" [multiple]="true" accept=".txt"
                    (onSelect)="selecionarAnexo($event)" [auto]="false" [showUploadButton]="false" (onRemove)="removerAnexo($event)">
                </p-fileUpload>
                <br>
                <div style="margin-left: auto;">

                    <button style="padding-top: 10px; width: 150px;" pButton  pRipple label="Consolidar DIRF"
                        type="button" icon="pi pi-file" class="p-button-warning mr-2" (click)="abrirModalConfirmaConsolidacao()"></button>
                    
                </div>
            </p-dialog>
            <!-- Modal Confirmacao Consolida Dirf -->
            <p-dialog [(visible)]="consolidaDirfModal" header="Consolidação Dirf" [modal]="true" [style]="{width:'450px'}">
                <div class="flex align-items-center justify-content-center">
                    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                    <span>Deseja Confirmar a
                        operação de <b>Consolidação da Dirf</b> ?</span>
                </div>
                <ng-template pTemplate="footer">
                    <button pButton pRipple icon="pi pi-times" class="p-button-text" label="Não"
                        (click)="consolidaDirfModal = false"></button>
                    <button type="submit" pButton style="padding-top: 10px; width: 150px;" pRipple label="Consolidar DIRF"
                        icon="pi pi-file" class="p-button-warning mr-2" [loading]="loadingButton"></button>
                </ng-template>
            </p-dialog>
        </form>
       
    </div>
</div>