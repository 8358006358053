import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { ThemeEnum } from './timp/enum/ThemeEnum.enum';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me'; // Prod graph endpoint. Uncomment to use.
const GRAPH_ENDPOINT_GET_PHOTO = 'https://graph.microsoft.com/v1.0/me/photo/$value';


type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  displayName?: string,
  id?: string
};

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls:['./app.topbar.component.css']
})
export class AppTopBarComponent implements OnInit {
  profileUser!: ProfileType;
  isImageLoading: boolean = false;
  imageToShow: any;

  constructor(public app: AppComponent, public appMain: AppMainComponent, private domSanitizer: DomSanitizer, private http: HttpClient, private authService: MsalService) { }
  ngOnInit() {
    this.getProfile();
    this.getProfilePicture();
  }

  getProfilePicture() {
    this.isImageLoading = true;
    this.getImage(GRAPH_ENDPOINT_GET_PHOTO).subscribe(blob => {
      this.isImageLoading = false;

      var urlCreator = window.URL || window.webkitURL;
      this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(
        urlCreator.createObjectURL(blob)
      );
    },
      (error) => {
        this.isImageLoading = false;
        console.log(error);
      }
    );
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profileUser = profile;
      });
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.http
      .get(imageUrl, {
        responseType: 'blob',
        headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  logout() {
    window.localStorage.clear();
    this.authService.logoutRedirect();
  }

/** processo de aumentar e diminuir fonte */
font_size = 13;

setFontSize(idd: string) {

  if(idd === 'up'){
    this.font_size += 1;
  } else if(idd === 'down') {
    this.font_size -= 1;
  } else {
    this.font_size = 13;
  }
  document.documentElement.style.fontSize=`${this.font_size}px`

}

/** processo de mudar o tema */
currentTheme: string = ThemeEnum.THEME_CURRENT
icon: string = ThemeEnum.ICON_CONTRAST

toggleTheme(): void {
  if (this.currentTheme == ThemeEnum.THEME_CURRENT ) {
    this.icon = ThemeEnum.ICON_CONTRAST_ADD
    this.currentTheme = ThemeEnum.THEME_NEW;
  } else {
    this.icon = ThemeEnum.ICON_CONTRAST;
    this.currentTheme = ThemeEnum.THEME_CURRENT;
  }
  this.applyTheme(this.currentTheme);
}

private applyTheme(theme: string): void {
  const themeLink = document.getElementById('theme-css') as HTMLLinkElement;
  if (themeLink) {
    themeLink.href = `./assets/theme/${theme}.css`;
    
  }
}

}

