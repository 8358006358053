<div class="layout-menu-wrapper" [class.layout-menu-dark]="app.darkMenu" (click)="appMain.onMenuClick($event)">
        <div class="layout-menu-container">
            <div class="mobile-search">
                <i class="pi pi-fw pi-search topbar-search-icon"></i>
                <input type="text" class="topbar-search" placeholder="Type your search here"/>
            </div>

            <ul class="layout-menu">
                <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
            </ul>
        </div>
</div>

