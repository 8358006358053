import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { MsalService } from "@azure/msal-angular";


@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppComponent, public appMain: AppMainComponent) { }

    ngOnInit() {
        this.model = [
            {
                label: 'DIRF', icon: 'pi pi-fw pi-building', routerLink: ['/view'], badge: 10,
                items: [
                    { label: 'CONSOLIDADOR', icon: 'pi pi-user', routerLink: [''] },
                    { label: 'INFORME DE RENDIMENTO', icon: 'pi pi-file', routerLink: ['timp/informe'] },


                ]
            },
        ];
    }
}
