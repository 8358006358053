<div class="grid">
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <div class="card">
                <h5>INFORME DE RENDIMENTO</h5>
                <div class="grid p-fluid">
                    <div class="col-12 md:col-3">
                        <div class="p-inputgroup">
                            <input type="text" pInputText maxlength="14" [(ngModel)]="filtroInformeRendimentos.cpfCnpj"
                                placeholder="CPF ou CNPJ" (keypress)="validateNumberInput($event)">
                        </div>
                    </div>
                    <div class="col-12 md:col-3">
                        <p-calendar placeholder="Ano calendario (Ano Base)"
                            [(ngModel)]="filtroInformeRendimentos.anoCalendario" view="year" dateFormat="yy"
                            inputId="yearpicker"></p-calendar>
                    </div>
                </div>
                <div class="my-2">
                    <app-button buttonType="search" (click)="buscar()"></app-button>
                    <app-button buttonType="clear" (click)="limpaFiltro()"></app-button>
                </div>
            </div>

            <!-- Lista de rendimentos por CPF-->
            <div *ngIf="rendimentoCpf != null" class="layout-menuitem-root-text">
                <p-table [loading]="submitted">
                    <ng-template pTemplate="caption">
                       
                        <div class="d-flex justify-content-between align-items-start">

                            <h5 class="m-0">PRESTADOR: {{rendimentoCpf.nome_prestador}}</h5>
                            <h5 class="m-0">CPF / CNPJ: {{formatarDocumento(rendimentoCpf.cpf_prestador)}}</h5>
                            <h5 class="m-0">ANO BASE: {{rendimentoCpf.ano_calendario}}</h5>
                        </div>
                    </ng-template>
                </p-table>
                <br />
                <p-table [value]="rendimentoCpfList">
                    <ng-template pTemplate="header">
                      
                        <br>
                        <tr>
                            <th>CODIGO DE RETENÇÃO:{{rendimentoCpf.codigo_retencao + " " +
                            rendimentoCpf.descricao_retencao}}</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>Rendimento total</th>
                            <th>Imposto Retido</th>
                            <th></th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rendimento>
                       
                        <tr>
                            <td style="width:auto;"><span class="p-column-title">Rendimento total</span>
                                {{"R$ "+rendimento.valor_rendimento}}
                            </td>
                            <td style="width:auto;"><span class="p-column-title">Imposto retido</span>
                                {{"R$ "+rendimentoCpf.valor_imposto}}
                            </td>
                          <td> <div class="layout-menuitem-root-text" style="float: right;" > <button pButton pRipple icon="pi pi-file-pdf" pTooltip="Gerar PDF"  (click)="DownloadPdf()"></button></div></td>
                           
                        </tr>
                    </ng-template>
                </p-table>

            </div>
            <!-- Lista de rendimentos por CNPJ-->
            <div *ngIf="rendimentoCnpjCabecalho != null" class="layout-menuitem-root-text">
                <p-table [loading]="submitted"> 
                    <ng-template pTemplate="caption">
                        <div class="layout-menuitem-root-text" style="float: right;" > <button pButton pRipple icon="pi pi-file-pdf" pTooltip="Gerar PDF"  (click)="DownloadPdf()"></button></div>
                        <div class="d-flex justify-content-between align-items-start">
                            <h5 class="m-0">PRESTADOR: {{rendimentoCnpjCabecalho.nome_prestador}}</h5>
                            <h5 class="m-0">CPF / CNPJ: {{formatarDocumento(rendimentoCnpjCabecalho.cpf_prestador)}}</h5>
                            <h5 class="m-0">ANO BASE: {{rendimentoCnpjCabecalho.ano_calendario}}</h5>
                           
                           
                        </div>
                      
                    </ng-template>
                   
                </p-table>


                <div *ngFor="let rendimento of rendimentoCnpj">
                    <p-table [value]="rendimento.rendimentos" [loading]="submitted">
                        <ng-template pTemplate="header">
                            <br>
                            <tr>
                                <th>CODIGO DE RETENÇÃO:{{rendimento.rendimentos[0].codigo_retencao}} -
                                    {{rendimento.rendimentos[0].descricao_retencao}}</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>Mês</th>
                                <th>Rendimento total</th>
                                <th>Imposto Retido</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rendimento>
                            <tr>
                                <td>{{ getNomeMes(rendimento.mes_retencao) }}</td>
                                <td>{{ "R$ " + rendimento.valor_rendimento }}</td>
                                <td>{{ "R$ " + rendimento.valor_imposto }}</td>
                                
                            </tr>

                        </ng-template>
                        <br>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
   
       

</div>