import { Component } from '@angular/core';
import { environment as env } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer clearfix">
            <img src="assets/layout/images/logo-footer.png">
            <div class="layout-footer-right">
                <span>${env.versao}</span>
            </div>
        </div>
    `
})
export class AppFooterComponent {

}
