<div class="grid dashboard">
    <div class="col-6 md:col-4 xl:col-2">
        <div class="card overview-box blue">
            <i class="pi pi-globe"></i>
            <h5>Global Listings</h5>
            <span>4 Active Listings</span>
        </div>
    </div>
    <div class="col-6 md:col-4 xl:col-2">
        <div class="card overview-box green">
            <i class="pi pi-dollar"></i>
            <h5>Today's Sales</h5>
            <span>$784.00 sales so far</span>
        </div>
    </div>
    <div class="col-6 md:col-4 xl:col-2">
        <div class="card overview-box orange">
            <i class="pi pi-tag"></i>
            <h5>Open Orders</h5>
            <span>14 Pending Orders</span>
        </div>
    </div>
    <div class="col-6 md:col-4 xl:col-2">
        <div class="card overview-box lime">
            <i class="pi pi-money-bill"></i>
            <h5>Total Balance</h5>
            <span>$87,974.00</span>
        </div>
    </div>
    <div class="col-6 md:col-4 xl:col-2">
        <div class="card overview-box purple">
            <i class="pi pi-envelope"></i>
            <h5>Buyer Messages</h5>
            <span>1 Unread Message</span>
        </div>
    </div>
    <div class="col-6 md:col-4 xl:col-2">
        <div class="card overview-box turquoise">
            <i class="pi pi-desktop"></i>
            <h5>Today's Sessions</h5>
            <span>1028 sessions so far</span>
        </div>
    </div>

    <div class="col-12 xl:col-8">
        <div class="card revenue-graph">
            <div class="card-header">
                <h5>Total Revenue</h5>
                <div id="order-tabs-container" class="order-tabs">
                    <div class="order-tab" data-label="New Sales" data-index="0" data-stroke="#3984b8" [ngClass]="{'order-tab-active':activeOrders===0}"
                         (click)="changeDataset($event); orders.refresh()">
                        <h6>Week</h6>
                    </div>
                    <div class="order-tab " data-label="Previous Sales" data-index="1" data-stroke="#BAD638" [ngClass]="{'order-tab-active':activeOrders===1}"
                         (click)="changeDataset($event); orders.refresh()">
                        <h6>Month</h6>
                    </div>
                </div>
            </div>
            <div class="overview-chart">
                <p-chart #orders type="bar" [data]="ordersChart" [options]="ordersOptions" [responsive]="true" height="450px"></p-chart>
            </div>
        </div>
    </div>
    <div class="col-12 xl:col-4">
        <div class="card slider-list">
            <div class="card-header">
                <h5>Best Selling Products</h5>
                <button pButton pRipple type="button" label="View More" class="p-button-outlined"></button>
            </div>
            <div class="slide-row-wrapper">
                <div class="slide-row"
                     [ngStyle]="{'transform': 'translate3d(' + (-1*(100*activeProduct))+'%, 0, 0)'} ">
                    <div class="slide ">
                        <div class="item-content">
                            <img src="assets/demo/images/product/bamboo-watch.jpg" alt="paradise-layout" />
                            <span class="product-badge status-instock">In Stock</span>
                            <div class="item-info">
                                <h5>Bamboo Watch</h5>
                                <span>Accessories</span>
                            </div>
                            <button pButton pRipple type="button" label="View This Item" class="p-button-outlined"></button>
                        </div>
                    </div>
                    <div class="slide ">
                        <div class="item-content">
                            <img src="assets/demo/images/product/black-watch.jpg" alt="paradise-layout" />
                            <span class="product-badge status-instock">In Stock</span>
                            <div class="item-info">
                                <h5>Black Watch</h5>
                                <span>Accessories</span>
                            </div>
                            <button pButton pRipple type="button" label="View This Item" class="p-button-outlined"></button>
                        </div>
                    </div>
                    <div class="slide ">
                        <div class="item-content">
                            <img src="assets/demo/images/product/brown-purse.jpg" alt="paradise-layout" />
                            <span class="product-badge status-instock">In Stock</span>
                            <div class="item-info">
                                <h5>Brown Purse</h5>
                                <span>Accessories</span>
                            </div>
                            <button pButton pRipple type="button" label="View This Item" class="p-button-outlined"></button>
                        </div>
                    </div>
                    <div class="slide ">
                        <div class="item-content">
                            <img src="assets/demo/images/product/galaxy-earrings.jpg" alt="paradise-layout" />
                            <span class="product-badge status-instock">In Stock</span>
                            <div class="item-info">
                                <h5>Galaxy Earrings</h5>
                                <span>Accessories</span>
                            </div>
                            <button pButton pRipple type="button" label="View This Item" class="p-button-outlined" ></button>
                        </div>
                    </div>
                    <div class="slide ">
                        <div class="item-content">
                            <img src="assets/demo/images/product/teal-t-shirt.jpg" alt="paradise-layout" />
                            <span class="product-badge status-instock">In Stock</span>
                            <div class="item-info">
                                <h5>Teal T-shirt</h5>
                                <span>Clothing</span>
                            </div>
                            <button pButton pRipple type="button" label="View This Item" class="p-button-outlined"></button>
                        </div>
                    </div>
                    <div class="slide">
                        <div class="item-content">
                            <img src="assets/demo/images/product/game-controller.jpg" alt="paradise-layout" />
                            <span class="product-badge status-instock">In Stock</span>
                            <div class="item-info">
                                <h5>Game Controller</h5>
                                <span>Electronics</span>
                            </div>
                            <button pButton pRipple type="button" label="View This Item" class="p-button-outlined"></button>
                        </div>
                    </div>
                </div>
                <div class="link-points">
                    <div class="points">
                        <div class="left-button slide-button" (click)="prevProduct($event)">
                            <i class="pi pi-arrow-left"></i>
                        </div>
                        <div class="right-button slide-button" (click)="nextProduct($event)">
                            <i class="pi pi-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 xl:col-6">
        <div class="grid">
            <div class="col-12 md:col-6">
                <div class="card status-box up">
                    <div class="status-info">
                        <h5>Conversion Rate</h5>
                        <span><i class="pi pi-arrow-up"></i> 2.3% more</span>
                    </div>
                    <h3>22.6%</h3>
                </div>
            </div>
            <div class="col-12 md:col-6">
                <div class="card status-box down">
                    <div class="status-info">
                        <h5>Sales This Week</h5>
                        <span><i class="pi pi-arrow-down"></i> 12.3% less</span>
                    </div>
                    <h3>189</h3>
                </div>
            </div>
            <div class="col-12 md:col-6">
                <div class="card status-box up">
                    <div class="status-info">
                        <h5>Active Customers</h5>
                        <span><i class="pi pi-arrow-up"></i> 19.6% more</span>
                    </div>
                    <h3>8272</h3>
                </div>
            </div>
            <div class="col-12 md:col-6">
                <div class="card status-box up">
                    <div class="status-info">
                        <h5>Active Products</h5>
                        <span><i class="pi pi-arrow-up"></i> 4.3% more</span>
                    </div>
                    <h3>4890</h3>
                </div>
            </div>

            <div class="col-12">
                <div class="card summary">
                    <div class="card-header">
                        <h5>Finance Summary</h5>
                        <button pButton pRipple type="button" label="View More" class="p-button-outlined"></button>
                    </div>
                    <div class="grid p-nogutter">
                        <div class="col-6 md:col-4">
                            <div class="summary-item colored">
                                <h6>Total Balance (All Accounts)</h6>
                                <h4>$87,974.00</h4>
                            </div>
                        </div>
                        <div class="col-6 md:col-4">
                            <div class="summary-item">
                                <h6>Total Sales (Month)</h6>
                                <h4>$23,038.12</h4>
                            </div>
                        </div>
                        <div class="col-6 md:col-4">
                            <div class="summary-item">
                                <h6>Refunds (Month)</h6>
                                <h4>$490.31</h4>
                            </div>
                        </div>
                        <div class="col-6 md:col-4">
                            <div class="summary-item">
                                <h6>Funds Available</h6>
                                <h4>$32,038.12</h4>
                            </div>
                        </div>
                        <div class="col-6 md:col-4">
                            <div class="summary-item">
                                <h6>Avarage Product Price</h6>
                                <h4>$129.90</h4>
                            </div>
                        </div>
                        <div class="col-6 md:col-4">
                            <div class="summary-item">
                                <h6>Satisfaction Rate</h6>
                                <p-rating [ngModel]="4" readonly="true"></p-rating>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 xl:col-6">
        <div class="card payments">
            <div class="card-header">
                <h5>Latest Payments</h5>
                <button pButton pRipple type="button" label="View More" class="p-button-outlined"></button>
            </div>
            <ul>
                <li>
                    <div class="progress">
                        <p-progressBar [value]="60" [showValue]="false"></p-progressBar>
                        <span>Proce­ssing</span>
                    </div>
                    <div class="payment-amount">
                        <h6>Payment Amount</h6>
                        <h5>$3,518.99</h5>
                    </div>
                    <div class="payment-status">
                        <h6>Payment Status</h6>
                        <h5><i class="pi pi-info-circle"></i> Started</h5>
                    </div>
                </li>

                <li >
                    <div class="progress">
                        <p-progressBar [value]="80" [showValue]="false"></p-progressBar>
                        <span>Sent to bank</span>
                    </div>
                    <div class="payment-amount">
                        <h6>Payment Amount</h6>
                        <h5>$2,124.66</h5>
                    </div>
                    <div class="payment-status">
                        <h6>Payment Status</h6>
                        <h5><i class="pi pi-info-circle"></i> Started</h5>
                    </div>
                </li>

                <li class="done">
                    <div class="progress">
                        <p-progressBar [value]="100" [showValue]="false"></p-progressBar>
                        <span>Ackno­wledge­d by bank</span>
                    </div>
                    <div class="payment-amount">
                        <h6>Payment Amount</h6>
                        <h5>$1,952.20</h5>
                    </div>
                    <div class="payment-status">
                        <h6>Payment Status</h6>
                        <h5><i class="pi pi-check"></i> Done</h5>
                    </div>
                </li>

                <li class="done">
                    <div class="progress">
                        <p-progressBar [value]="100" [showValue]="false"></p-progressBar>
                        <span>Ackno­wledge­d by bank</span>
                    </div>
                    <div class="payment-amount">
                        <h6>Payment Amount</h6>
                        <h5>$1,952.20</h5>
                    </div>
                    <div class="payment-status">
                        <h6>Payment Status</h6>
                        <h5><i class="pi pi-check"></i> Done</h5>
                    </div>
                </li>

                <li class="done">
                    <div class="progress">
                        <p-progressBar [value]="100" [showValue]="false"></p-progressBar>
                        <span>Ackno­wledge­d by bank</span>
                    </div>
                    <div class="payment-amount">
                        <h6>Payment Amount</h6>
                        <h5>$1,952.20</h5>
                    </div>
                    <div class="payment-status">
                        <h6>Payment Status</h6>
                        <h5><i class="pi pi-check"></i> Done</h5>
                    </div>
                </li>

                <li class="done">
                    <div class="progress">
                        <p-progressBar [value]="100" [showValue]="false"></p-progressBar>
                        <span>Ackno­wledge­d by bank</span>
                    </div>
                    <div class="payment-amount">
                        <h6>Payment Amount</h6>
                        <h5>$1,952.20</h5>
                    </div>
                    <div class="payment-status">
                        <h6>Payment Status</h6>
                        <h5><i class="pi pi-check"></i> Done</h5>
                    </div>
                </li>

            </ul>
        </div>
    </div>

    <div class="col-12  xl:col-4">
        <div class="card tasks">
            <div class="card-header">
                <h5>Tasks</h5>
                <button pButton pRipple type="button" label="Add New" icon="pi pi-plus" class="p-button-outlined"></button>
            </div>
            <ul>
                <li>
                    <span class="task" >Restock Inventory</span>
                    <span>03 Sep, 2020</span>
                    <div class="category blue">
                        <span>Admin</span>
                    </div>
                </li>
                <li>
                    <span class="task">Design Mockups</span>
                    <span>03 Sep, 2020</span>
                    <div class="category orange">
                        <span>Project</span>
                    </div>
                </li>
                <li>
                    <span class="task">Conference</span>
                    <span>03 Sep, 2020</span>
                    <div class="category purple">
                        <span>Event</span>
                    </div>
                </li>
                <li>
                    <span class="task">Hire Add Manager</span>
                    <span>03 Sep, 2020</span>
                    <div class="category orange">
                        <span>Admin</span>
                    </div>
                </li>
                <li>
                    <span class="task">Bussiness Meeting</span>
                    <span>03 Sep, 2020</span>
                    <div class="category purple">
                        <span>Event</span>
                    </div>
                </li>
                <li>
                    <span class="task">Hire Add Manager</span>
                    <span>03 Sep, 2020</span>
                    <div class="category green">
                        <span>Managment</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="col-12 xl:col-8">
        <div class="card sales">
            <div class="card-header">
                <h4>Recent Sales</h4>
                <p-dropdown [options]="orderWeek" [(ngModel)]="selectedOrderWeek" optionLabel="name" (onChange)="recentSales($event)"
                            styleClass="dashbard-demo-dropdown" [ngStyle]="{'min-width': '8rem'}"></p-dropdown>
            </div>

            <p>Your sales activity over time.</p>


            <p-table [value]="products" [paginator]="true" [rows]="5" styleClass="p-datatable-customers">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="id">ID
                            <p-sortIcon field="id"></p-sortIcon>
                        </th>
                        <th pSortableColumn="category">Category
                            <p-sortIcon field="category"></p-sortIcon>
                        </th>
                        <th pSortableColumn="price">Price
                            <p-sortIcon field="price"></p-sortIcon>
                        </th>
                        <th pSortableColumn="inventoryStatus">Status
                            <p-sortIcon field="inventoryStatus"></p-sortIcon>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>
                            <span class="p-column-title">Id</span>
                            {{product.id}}</td>
                        <td>
                            <span class="p-column-title">Category</span>
                            {{product.category}}</td>
                        <td>
                            <span class="p-column-title">Price</span>
                            {{product.price | currency:'USD'}}</td>
                        <td>
                            <span class="p-column-title">Status</span>
                            <span
                            [class]="'product-badge status-' + product.inventoryStatus.toLowerCase()">{{product.inventoryStatus}}</span>
                        </td>
                        <td style="text-align: center">
                            <button pButton type="button" icon="pi pi-search"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

</div>
