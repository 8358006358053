import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { DirfService } from '../service/dirf.service';
import { Documento, DocumentoPaginadoDTO, DocumentoResumoDTO, FiltroDocumento } from '../domain/dirf';
import { FileUpload } from 'primeng/fileupload';


@Component({
  templateUrl: './dirf.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['../../../assets/demo/badges.scss'],
  styles: [`
    :host ::ng-deep .p-frozen-column {
      font-weight: bold;
    }

    :host ::ng-deep .p-datatable-frozen-tbody {
      font-weight: bold;
    }

    :host ::ng-deep .p-progressbar {
      height: .5rem;
    }
  `]
})

export class DirfComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload!: FileUpload;
  consolidaDirfDialog: boolean = false;
  submitted: boolean = false;
  loadingButton: boolean = false;
  retificadoraLista: any;
  statusProcessamentoLista: any;
  files: File[] = new Array();
  qtdLinhasGrid: number = 10;
  filtroDocumento: FiltroDocumento;
  first = 0;
  cols: any[];
  documentosGrid: DocumentoResumoDTO[] = new Array();
  totalRegistrosGrid: number = 0;
  selectedDocumentos: Documento[] = new Array();
  documentoPaginadoDTO: DocumentoPaginadoDTO = new DocumentoPaginadoDTO();
  retificadora: string = '';
  arquivosLegados: boolean = false;
  arquivosLegadosOpcoes: any [];
  anoBase: number;
  identificadorLeiaute: string;
  consolidaDirfModal: boolean = false;
  arquivosDuplicados: string[] = [];

  constructor(private messageService: MessageService, private confirmationService: ConfirmationService,
    private msalService: MsalService, private dirfService: DirfService) {

  }

  ngOnInit() {
    this.filtroDocumento = <FiltroDocumento>({
      nomeArquivo: null,
      retificadora: null,
      anoReferencia: null,
      status: null,
      tamanhoPagina: null,
      numPagina: null
    });




    this.retificadoraLista = [
      { nome: 'SIM', code: 'S' },
      { nome: 'NÃO', code: 'N' },

    ];

    this.statusProcessamentoLista = [
      { nome: 'EM PROCESSAMENTO', code: 'EM PROCESSAMENTO' },
      { nome: 'SUCESSO', code: 'SUCESSO' },
      { nome: 'FALHA', code: 'FALHA' },


    ];

    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'nomeArquivo', header: 'Arquivo' },
      { field: 'tipoDeclaracao', header: 'Retificadora' },
      { field: 'anoReferencia', header: 'Ano_referencia' },
      { field: 'anoCalendario', header: 'Ano_calendario' },
      { field: 'identificadorLeiaute', header: 'Identificador_leiaute' },
      { field: 'numeroRecibo', header: 'Numero_recibo' },
      { field: 'pathConsolidado', header: 'Path_cosolidado' },
      { field: 'status', header: 'Status' },
      { field: 'dataCriacao', header: 'Data_criacao' },
      { field: 'usuarioCriacao', header: 'Usuario_criacao' },

    ];

    this.arquivosLegadosOpcoes = [
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ];

    this.buscar(false);

  }

  limpaFiltro() {


    this.filtroDocumento = <FiltroDocumento>({
      nomeArquivo: null,
      retificadora: null,
      anoReferencia: null,
      status: null,
      tamanhoPagina: null,
      numPagina: null,

    })
  }


  abrirCosolidaDirfForm() {
    this.consolidaDirfDialog = true;
  }

  async submitForm(form: NgForm) {
    if (!form.valid) {
      const camposInvalidos = Object.keys(form.controls).filter(controlName => form.controls[controlName].errors);

      if (camposInvalidos.length > 0) {
        const mensagemErro = `Existem campos obrigatórios sem o preenchimento correto! Por favor, verifique os campos: ${camposInvalidos.join(', ')}.`;
        this.messageService.add({ severity: 'warn', summary: 'Erro', detail: mensagemErro, life: 3000 });
      }

    } else if (this.retificadora == 'S' && !form.value.numeroRecibo) {
      this.messageService.add({ severity: 'warn', summary: 'Erro', detail: 'Número do Recibo obrigatório', life: 3000 });
    }
    else if (!this.arquivosLegados && this.files.length < 2) {
      this.messageService.add({ severity: 'warn', summary: 'Erro', detail: 'É necessário incluir pelo menos dois arquivos para consolidar', life: 3000 });
    }
    else if (this.arquivosLegados && this.files.length > 1 ) {
      this.messageService.add({ severity: 'warn', summary: 'Erro', detail: 'Para enviar arquivos legados inclua somente um arquivo', life: 3000 });
    }
    else {
      this.loadingButton = true;
      const documento = new Documento(
        form.value.anoReferencia.getFullYear(),
        form.value.anoCalendario.getFullYear(),
        form.value.leiaute,
        this.retificadora != 'S' ? 'N' : this.retificadora,
        form.value.numeroRecibo
      );

      try {
        await this.dirfService.uploadArquivo(this.files, documento);
        this.buscar(true);
        this.files = [];
        this.fileUpload.clear();
        form.resetForm();
        this.retificadora = '';
        this.consolidaDirfDialog = false;
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Arquivo enviado com sucesso!, o status será EM PROCESSAMENTO', life: 3000 });
      } catch (e) {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao fazer upload de arquivos', life: 3000 });
      }
      this.consolidaDirfModal = false;
      this.loadingButton = false;
    }
  }


  buscarDocumentoPorPagina(event: LazyLoadEvent) {

    if (this.qtdLinhasGrid !== event.rows) {
      this.qtdLinhasGrid = event.rows;
      this.first = 0;
      this.filtroDocumento.numPagina = 1;
      this.filtroDocumento.tamanhoPagina = event.rows;
      this.buscar(true);
    }
    else {
      if (event.first === 0) {
        this.filtroDocumento.numPagina = 1;
        this.filtroDocumento.tamanhoPagina = this.qtdLinhasGrid;

      } else if (event.first > 0) {
        this.filtroDocumento.numPagina = event.first / event.rows + 1;
        this.filtroDocumento.tamanhoPagina = this.qtdLinhasGrid;

      }
      this.buscar(false);
    }
  }

  async selecionarAnexo(event) {
    let arquivosDuplicados = [];

    for (let file of event.files) {
      if (!this.arquivoComMesmoNomeJaExiste(file.name)) {
        this.files.push(file);
      } else {
        arquivosDuplicados.push(file.name);
      }
    }

    if (arquivosDuplicados.length > 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Erro',
        detail: `Arquivos com os seguintes nomes já existem na lista: ${arquivosDuplicados.join(', ')}. Modifique os nomes dos arquivos`,
        life: 3000
      });
    }
  }

  arquivoComMesmoNomeJaExiste(nomeDoArquivo) {
    return this.files.some(file => file.name === nomeDoArquivo);
  }



  async buscar(reset: boolean) {

    this.submitted = true;
    this.selectedDocumentos = new Array();
    this.documentosGrid = new Array();
    if (reset) { this.first = 0 };
    await this.dirfService.getDocumentoPaginado(this.filtroDocumento).then(data => {

      this.documentoPaginadoDTO = data;
      this.documentosGrid = data.documentos;
      this.totalRegistrosGrid = data.qtdRegistros;
    }, (err) => {
      console.log(err);
      this.submitted = false;
      this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Erro tentativa buscar documentos', life: 3000 });
    });

    this.submitted = false;
  }

  fecharDirfDialog(form: NgForm) {
    this.files = [];
    this.fileUpload.clear();
    form.resetForm();
    this.retificadora = '';
    this.consolidaDirfDialog = false;
  }
  atualizarNumeroRecibo(form: NgForm) {
   if(this.retificadora != 'S'){
     form.controls['numeroRecibo'].setValue('');
   }
   
  }

  downloadDirf(pathDocumento: string, id: number, nomeArquivo:string) {
   
    this.submitted = true;

    if (!pathDocumento || pathDocumento.trim() === '') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Status',
        detail: 'Documento em processamento',
        life: 3000
      });
      this.submitted = false;
      return; 
    }

    this.dirfService.downloadDirf(pathDocumento.replace(/https:\/\/apssttimpbs(qa|dev|prd)\.blob\.core\.windows\.net\/timp\//, ''))
      .then((data: ArrayBuffer) => {
        this.dirfService.downLoadFile(data, id, nomeArquivo);

        this.messageService.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Download concluído",
          life: 3000
        });
        this.submitted = false;
      },
        (err) => {
          console.log(`Erro download arquivo : ${JSON.stringify(err)}`);
          this.submitted = false;
          this.messageService.add({
            severity: "error",
            detail: "Erro:  Falha ao executar o download do arquivo ",
            life: 3000
          });
          this.submitted = false;
        });
  }

  limitarTamanho(event: any) {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
    if (event.target.value.length > 12) {
      event.target.value = event.target.value.slice(0, 12);
    }
   }

  atualizarIdentificadorLeiaute(form: NgForm) {
    const anoReferencia = form.value.anoReferencia;
    if (anoReferencia instanceof Date) {
      const anoCalendario = anoReferencia.getFullYear();
      switch (anoCalendario) {
        case 2024:
          form.controls['leiaute'].setValue('B3VH8RQ');
          break;
        case 2023:
          form.controls['leiaute'].setValue('ARNZRXP');
          break;
        case 2022:
          form.controls['leiaute'].setValue('XJFSFHB');
          break;
        case 2021:
          form.controls['leiaute'].setValue('VR4QLM8');
          break;
        default:
          form.controls['leiaute'].setValue('');
          break;
      }
    }
  }


  removerAnexo(event: any) {
    const removedFileIndex = this.files.findIndex(file => file.name === event.file.name);
    if (removedFileIndex !== -1) {
      this.files.splice(removedFileIndex, 1);
    }

    const duplicatedFileIndex = this.arquivosDuplicados.findIndex(file => file === event.file.name);
    if (duplicatedFileIndex !== -1) {
      this.arquivosDuplicados.splice(duplicatedFileIndex, 1);
    }
  }

  abrirModalConfirmaConsolidacao() {
    this.consolidaDirfModal = true;
  }


}