<div class="login-body">

    <div class="login-panel p-fluid">
        <div class="grid grid-nogutter">
            <div class="col-12 logo-container">
                <img src="assets/layout/images/logo.png" class="logo">
                <img src="assets/layout/images/login/lock.png" class="login-icon" >
                <h2>Entre com sua conta Saúde Petrobras</h2>
            </div>
            <div class="col-12 button-container">
                <button type="button" pButton label="Entrar" class="p-button-info p-button-raised" (click)="login()"></button>
            </div>
            <div class="container">
                <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
                <router-outlet *ngIf="!isIframe"></router-outlet>
            </div>
        </div>
    </div>
</div>
